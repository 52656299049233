import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class financialWHTService {

    async get(period, profile) {
        const config = {
            headers: authHeader()
        };

        const response = await axios
            .post(`${API_URL}financial-graph/wht`, { period, profile }, config);
        return response.data;
    }


}

export default new financialWHTService();