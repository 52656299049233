import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class FinancialEarningService {

  async get(period) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}financial-graph/earnings`, {
        period: period
      }, config);

    return response.data;
  }

 
}

export default new FinancialEarningService();